import styled from 'styled-components';
import { Radio } from 'antd';
import { mainTheme } from 'constant';

export const CustomRadio = styled(Radio)`
    color: ${(props) => props.theme.sub};

    .ant-radio-inner {
        background-color: ${(props) => props.theme.card};
        border-color: ${(props) => props.theme.border};
    }

    &.ant-radio-checked .ant-radio-inner {
        border-color: ${mainTheme.primary};
    }
`;
