import {
    StyleLdsSpinner, StyleLdsSpinnerDiv, StyleFixed, StyleFled, StyleWrapper
} from './style.module';

export const LoadingPage = () => (
    <StyleFixed>
        <StyleFled>
            <StyleWrapper>
                <StyleLdsSpinner>
                    <StyleLdsSpinnerDiv />
                    <StyleLdsSpinnerDiv />
                    <StyleLdsSpinnerDiv />
                    <StyleLdsSpinnerDiv />
                    <StyleLdsSpinnerDiv />
                    <StyleLdsSpinnerDiv />
                    <StyleLdsSpinnerDiv />
                    <StyleLdsSpinnerDiv />
                    <StyleLdsSpinnerDiv />
                    <StyleLdsSpinnerDiv />
                    <StyleLdsSpinnerDiv />
                    <StyleLdsSpinnerDiv />
                </StyleLdsSpinner>
            </StyleWrapper>
        </StyleFled>
    </StyleFixed>
);
