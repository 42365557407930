import styled from 'styled-components';
import { Switch } from 'antd';
import { mainTheme } from 'constant';

export const CustomSwitch = styled(Switch)`
    background: ${(props) => props.theme.switch};

    .ant-switch-inner {
        color: #fff;
    }

    .ant-switch-handle:before {
        background: ${(props) => props.theme.card};
    }

    &.ant-switch-checked {
        background-color: ${mainTheme.primary};
    }
`;
