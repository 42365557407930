import { ThemeType } from 'utils';
import { GlobalDatePickerStyle } from './DatePicker';
import { GlobalSelectStyle } from './Select';
import {
    GlobalModalStyle, GlobalDropdownStyle, GlobalDrawerStyle, GlobalMessageStyle,
    GlobalCommonStyle,
} from './Layout';

export const GlobalStyle = ({ theme }: { theme: ThemeType }) => (
    <>
        <GlobalDatePickerStyle theme={theme} />
        <GlobalSelectStyle theme={theme} />
        <GlobalModalStyle theme={theme} />
        <GlobalDropdownStyle theme={theme} />
        <GlobalDrawerStyle theme={theme} />
        <GlobalMessageStyle theme={theme} />
        <GlobalCommonStyle theme={theme} />
    </>
);
