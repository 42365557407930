import styled from 'styled-components';
import { mainTheme } from 'constant';
import { Input } from 'antd';

export const CustomInput = styled(Input)`
    background: ${(props) => props.theme.card};
    border-color: ${(props) => props.theme.border};
    color: ${(props) => props.theme.sub};
    height: ${mainTheme.formItemHeight}px;
    font-size: ${mainTheme.fontNormal}px;

    &.ant-input-affix-wrapper-status-error {
        background: ${(props) => props.theme.card} !important;
    }

    &:focus, &:hover, &.ant-input-focused, &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: ${mainTheme.primary};
    }

    &[disabled] {
        background: ${(props) => props.theme.card};
        border-color: ${(props) => props.theme.border};
        color: ${(props) => props.theme.sub};
    }

    .ant-input {
        background: ${(props) => props.theme.card};
        color: ${(props) => props.theme.sub};
    }

    .ant-input-password-icon.anticon {
        color: ${(props) => props.theme.sub};
    }
`;
