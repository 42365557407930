export const mainTheme = {
    primary: '#3866B1',
    red: '#FF4D4F',
    venetian: '#D43535',
    padding: 24,
    margin: 15,
    fontLabel: 14,
    fontNormal: 16,
    fontSectionTitle: 24,
    fontTitlePublic: 30,
    fontTitle: 32,
    formItemHeight: 40,
    success: '#52C41A'
};

export const themes = {
    dark: {
        type: 'dark',
        background: '#000',
        card: '#141414',
        border: '#383838',
        dropdown: '#202020',
        normal: '#f8f9f9',
        sub: 'rgba(255, 255, 255, 0.85)',
        blur: 'rgba(255, 255, 255, 0.85)',
        selection: 'rgba(255, 255, 255, 0.08)',
        iconSelection: 'rgba(255, 255, 255, 0.45)',
        iconTime: 'rgba(255, 255, 255, 0.25)',
        thead: '#1d1d1d',
        theadSortIcon: 'rgba(255,255,255,0.25)',
        tborder: '#282828',
        texpandrow: 'rgba(255,255,255,0.09)',
        switch: 'rgba(255, 255, 255, 0.3)',
        tableBorder: '#D6D6D6',
        filterBackground: '#F3F5F6',
    },
    light: {
        type: 'light',
        background: '#fff',
        card: '#fff',
        border: '#E9E9E9',
        dropdown: '#fff',
        normal: '#000000',
        sub: '#434343',
        blur: '#363636',
        selection: '#f5f5f5',
        iconSelection: 'rgba(0, 0, 0, 0.45)',
        iconTime: 'rgba(0, 0, 0, 0.25)',
        thead: '#fafafa',
        theadSortIcon: '#bfbfbf',
        tborder: '#f0f0f0',
        texpandrow: '#fbfbfb',
        switch: '#00000040',
        tableBorder: '#D6D6D6',
        filterBackground: '#F3F5F6',
    },
};
