import { createContext } from 'react';
import { AuthType } from 'services/Login';

type AuthContextType = {
    user: AuthType | undefined,
    setUser: ((value: AuthType | undefined) => void) | undefined,
}
export const authContext = createContext<AuthContextType>({
    user: undefined,
    setUser: undefined
});
