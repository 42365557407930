import styled from 'styled-components';
import { Button } from 'antd';
import { mainTheme } from 'constant';

export const CustomButton = styled(Button)`
    outline: none;
    background: ${(props) => props.theme.card};
    border-color: ${(props) => props.theme.border};
    color: ${(props) => props.theme.sub};
    height: ${mainTheme.formItemHeight}px;
    font-size: ${mainTheme.fontNormal}px;
    line-height: ${mainTheme.formItemHeight - 10}px;
    
    &:hover, &:focus, &:active {
        color: ${mainTheme.primary};
        border-color: ${mainTheme.primary};
        background: ${(props) => props.theme.card};
    }

    &.ant-btn-primary {
        border-color: ${mainTheme.primary};
        background: ${mainTheme.primary};
        color: #fff;
        &:hover, &:focus, &:active {
            background: ${(props) => props.theme.card};
            color: ${(props) => props.theme.sub};
        }
        &[disabled], &[disabled]:active, &[disabled]:focus, &[disabled]:hover {
            background-color: #F5F5F5;
            border-color: #D9D9D9;
            color: #BFBFBF;
        }
    }
`;
