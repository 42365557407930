import { RoleEnum } from "services/Users";

export const LEVEL_ROLE = {
    [RoleEnum.SuperAdmin]: 100,
    [RoleEnum.Admin]: 50,
    [RoleEnum.Staff]: 1,
}

export const USER_MANAGEMENT = {
    view: 'user_management_view',
    edit: 'user_management_edit',
    create: 'user_management_create',
    delete: 'user_management_delete'
}

export const PERMISSIONS = {
    [RoleEnum.SuperAdmin]: [
        USER_MANAGEMENT.view, USER_MANAGEMENT.edit, USER_MANAGEMENT.create, USER_MANAGEMENT.delete,
    ],
    [RoleEnum.Admin]: [
        USER_MANAGEMENT.view, USER_MANAGEMENT.edit, USER_MANAGEMENT.create, USER_MANAGEMENT.delete,
    ],
    [RoleEnum.Staff]: [],
};
