import styled from 'styled-components';
import { Descriptions } from 'antd';

export const CustomDescriptions = styled(Descriptions)`
    .ant-descriptions-row > th, .ant-descriptions-row > td {
        padding-bottom: 12px;
    }
    
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
        color: ${(props) => props.theme.sub};
    }
`;
