import styled from 'styled-components';
import { mainTheme } from 'constant';
import { DatePicker } from 'antd';

export const CustomRangePicker = styled(DatePicker.RangePicker)`
    background-color: ${(props) => props.theme.card};
    border-color: ${(props) => props.theme.border};
    width: 100%;
    .ant-picker-input > input {
        color: ${(props) => props.theme.sub};
        ::placeholder {
            color: ${(props) => props.theme.theadSortIcon};
        }
    }

    .ant-picker-suffix,
    .ant-picker-separator {
        color: ${(props) => props.theme.iconTime};
    }

    .ant-picker-clear {
        background-color: ${(props) => props.theme.card};
        color: ${(props) => props.theme.iconTime};
        &:hover {
            color: ${(props) => props.theme.iconSelection};
        }
    }

    &:hover, &.ant-picker-focused {
        border-color: ${mainTheme.primary};
    }

    &.ant-picker-disabled {
        background-color: ${(props) => props.theme.card};
        border-color: ${(props) => props.theme.border};
        .ant-picker-suffix {
            color: ${(props) => props.theme.iconTime};
        }
    }

    .ant-picker-active-bar {
        background: ${mainTheme.primary};
    }
`;
