import { Moment } from 'moment';
import { LocaleEnum } from 'utils';

export enum RoleEnum {
    SuperAdmin = 'ROLE_SUPER_ADMIN',
    Admin = 'ROLE_ADMIN',
    Staff = 'ROLE_STAFF'
}

export type UserFilterType = {
    username?: string,
    email?: string,
    role?: RoleEnum,
    locale?: LocaleEnum,
    createdDate?: [Moment, Moment]
}

export type UserCreatingRequestType = {
    username: string,
    email: string,
    role: RoleEnum,
    locale: LocaleEnum
}

export type UsersResponseType = {
    id: string,
    key: string,
    username: string,
    role: RoleEnum,
    locale: LocaleEnum,
    email: string,
    createdDate: Moment,
}

export type UsersResponseRawType = {
    id: string,
    entityname: string,
    roles: string[],
    locale: string,
    email: string,
    created_at: string,
};

export type UserDetailsResponseType = {
    id: string,
    username: string,
    role: RoleEnum,
    locale: LocaleEnum,
    email: string
};

export type UserDetailsResponseRawType = {
    id: string,
    entityname: string,
    roles: string[],
    locale: string,
    email: string
};
