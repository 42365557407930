import { createContext } from 'react';
import { ClientsResponseType } from 'services/Client';

type ClientContextType = {
    client?: ClientsResponseType,
    refetch?: () => void,
}

export const clientContext = createContext<ClientContextType>({
    client: undefined,
    refetch: undefined
});
