import styled, { createGlobalStyle } from 'styled-components';
import { Layout } from 'antd';
import { ThemeType } from 'utils';
import { mainTheme } from 'constant';

export const CustomLayout = styled(Layout)`
    background: ${(props) => props.theme.background};
`;

export const GlobalModalStyle = createGlobalStyle<{theme: ThemeType}>`
    .ant-modal-header {
        background: ${(props) => props.theme.dropdown};
        border-bottom: 1px solid ${(props) => props.theme.tborder};
        color: ${(props) => props.theme.sub};
    }

    .ant-modal-close:focus, .ant-modal-close:hover,
    .ant-modal-title {
        color: ${(props) => props.theme.sub};
    }

    .ant-modal-close {
        color: ${(props) => props.theme.iconSelection};
    }

    .ant-modal-content {
        background: ${(props) => props.theme.dropdown};
    }

    .ant-modal-footer {
        border-top: 1px solid ${(props) => props.theme.tborder};
    }

    .delete-modal {
        .ant-btn-primary {
            border-color: ${mainTheme.red};
            background: ${mainTheme.red};
            color: #fff;
            &:hover, &:focus, &:active {
                background: ${(props) => props.theme.card};
                color: ${(props) => props.theme.sub};
            }
        }

        &.ant-modal-confirm-confirm .ant-modal-confirm-body>.anticon,
        &.ant-modal-confirm-warning .ant-modal-confirm-body>.anticon {
            color: ${mainTheme.venetian};
        }
    }
`;

export const GlobalDropdownStyle = createGlobalStyle<{theme: ThemeType}>`
    .ant-dropdown {
        color: ${(props) => props.theme.sub};
    }

    .ant-dropdown-menu {
        background-color: ${(props) => props.theme.card};
    }

    .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
    .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
    .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active,
    .ant-dropdown-menu-submenu-title:hover {
        background-color: ${(props) => props.theme.selection};
    }
`;

export const GlobalDrawerStyle = createGlobalStyle<{theme: ThemeType}>`
    .ant-drawer-header {
        background: ${(props) => props.theme.dropdown};
        border-bottom: 1px solid ${(props) => props.theme.tborder};
        color: ${(props) => props.theme.sub};
    }
    
    .ant-drawer-close:focus, .ant-drawer-close:hover,
    .ant-drawer-title {
        color: ${(props) => props.theme.sub};
    }

    .ant-drawer-close {
        color: ${(props) => props.theme.iconSelection};
    }

    .ant-drawer-content {
        background: ${(props) => props.theme.dropdown};
    }

    .ant-drawer-footer {
        border-top: 1px solid ${(props) => props.theme.tborder};
    }
`;

export const GlobalMessageStyle = createGlobalStyle<{theme: ThemeType}>`
    .ant-message {
        color: ${(props) => props.theme.normal};
    }

    .ant-message-notice-content {
        background: ${(props) => props.theme.card};
    }
`;

export const GlobalCommonStyle = createGlobalStyle<{theme: ThemeType}>`
    body {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 400;
        font-size: ${mainTheme.fontNormal}px;
        background-color: ${(props) => props.theme.card};
    }

    a, .main-color {
        color: ${mainTheme.primary};
    }

    ul, h1 {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .layout {
        &-full-screen {
            height: 100vh;
        }

        &-main-content {
            padding: 24px 24px 0;
            margin-bottom: 24px;
            overflow: auto;
        }
    }

    .full-width {
        width: 100%;
    }

    .no {
        &-margin {
            margin: 0;
        }

        &-padding {
            padding: 0;
        }

        &-background {
            background: transparent !important;
        }

        &-border {
            border: 0;
        }
    }

    .justify {
        &-between {
            justify-content: space-between;
        }
        &-center {
            justify-content: center;
        }
    }

    .flex {
        display: flex;

        &-col {
            flex-direction: column;
        }
        &-row {
            flex-direction: row;
        }
    }

    .items-center {
        align-items: center;
    }

    .gap {
        &-5 {
            gap: 5px;
        }

        &-10 {
            gap: 10px;
        }

        &-12 {
            gap: 12px;
        }

        &-15 {
            gap: 15px;
        }

        &-20 {
            gap: 20px;
        }

        &-24 {
            gap: 24px;
        }

        &-30 {
            gap: 30px;
        }
    }

    .margin-bottom {
        &-5 {
            margin-bottom: 5px;
        }
    
        &-12 {
            margin-bottom: 12px;
        }

        &-15 {
            margin-bottom: 15px;
        }

        &-32 {
            margin-bottom: 32px;
        }

        &-40 {
            margin-bottom: 40px;
        }

        &-44 {
            margin-bottom: 44px;
        }
    }

    .basis {
        &-6-12 {
            flex-basis: 50%;
        }
    }

    .text {
        &-center {
            text-align: center;
        }

        &-left {
            text-align: left;
        }

        &-xs {
            font-size: ${mainTheme.fontLabel}px;
        }

        &-sm {
            font-size: ${mainTheme.fontNormal}px;
        }
    }

    .font {
        &-light {
            font-weight: 300;
        }

        &-normal {
            font-weight: 400;
        }

        &-bold {
            font-weight: 700;
        }
    }

    .break {
        &-words {
            word-break: break-word;
        }
    }

    .float {
        &-right {
            float: right;
        }

        &-left {
            float: left;
        }
    }

    .cursor {
        &-pointer {
            cursor: pointer;
        }
    }

    .relative {
        position: relative;
    }

    .absolute {
        position: absolute;
    }

    .main-background {
        background-color: ${mainTheme.primary};
    }

    .main-color {
        color: ${mainTheme.primary};
    }

    .err-color {
        color: #ff4d4f;
    }

    .hide {
        display: none;
    }

    .truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
