import { Suspense, useState, lazy, useMemo, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LoadingPage } from 'components/LoadingPage';
import {
    PrivateRoute, publicRoutes, authContext, ThemeEnum, getDataOnDevice, Router
} from 'utils';
import { v4 } from 'uuid';
import { AuthType, getUserInfo } from 'services/Login';
import { LOCALSTORAGE_TOKEN_KEY, PRIVATE_ROUTE, themes } from 'constant';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'components/UI';

const Main = lazy(() => import('containers/Main/Main'));
const App = (): JSX.Element => {
    const [initApp, setInitApp] = useState<boolean>(false);
    const [theme] = useState<number>(ThemeEnum.Light);
    const [user, setUser] = useState<AuthType | undefined>();

    const getTheme = useMemo(() => {
        if (theme === ThemeEnum.Light) {
            return themes.light;
        }

        return themes.dark;
    }, [theme]);

    useEffect(() => {
        const token = getDataOnDevice(LOCALSTORAGE_TOKEN_KEY);
        if (token) {
            setUser(getUserInfo(token));
            setInitApp(true);
        } else {
            setUser(undefined);
            setInitApp(true);
        }
    }, []);

    if (!initApp) {
        return <LoadingPage />;
    }

    return (
        <Suspense fallback={<LoadingPage />}>
            <authContext.Provider value={{ user, setUser }}>
                <ThemeProvider theme={getTheme}>
                    <GlobalStyle theme={getTheme} />
                    <Router>
                        <Routes>
                            {publicRoutes.map((route) => (
                                <Route key={v4()} path={route.path} element={<route.component />}/>
                            ))}
                            <Route path='/' element={<PrivateRoute />}>
                                <Route path='/' element={<Navigate to={PRIVATE_ROUTE.CLIENTS} />}/>
                                <Route path='/*' element={<Main />}/>
                            </Route>
                        </Routes>
                    </Router>
                </ThemeProvider>
            </authContext.Provider>
        </Suspense>
    );
}

export default App;
