import styled from 'styled-components';
import { TimePicker } from 'antd';
import { mainTheme } from 'constant';

export const CustomTimePicker = styled(TimePicker)`
    background-color: ${(props) => props.theme.card};
    border-color: ${(props) => props.theme.border};
    .ant-picker-input > input {
        color: ${(props) => props.theme.sub};
        ::placeholder {
            color: ${(props) => props.theme.theadSortIcon};
        }
    }

    .ant-picker-suffix {
        color: ${(props) => props.theme.iconTime};
    }

    .ant-picker-clear {
        background-color: ${(props) => props.theme.card};
        color: ${(props) => props.theme.iconTime};
        &:hover {
            color: ${(props) => props.theme.iconSelection};
        }
    }

    &:hover, &.ant-picker-focused {
        border-color: ${mainTheme.primary};
    }

    &.ant-picker-disabled {
        background-color: ${(props) => props.theme.card};
        border-color: ${(props) => props.theme.border};
        .ant-picker-suffix {
            color: ${(props) => props.theme.iconTime};
        }
    }
`;
