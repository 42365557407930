import moment from 'moment';
import { LocaleEnum } from 'utils';
import {
    UsersResponseType, UsersResponseRawType, RoleEnum,
    UserDetailsResponseType, UserDetailsResponseRawType
} from './types';

export const convertToRole = (role: string[]): RoleEnum => {
    switch (role[0]) {
        case 'ROLE_SUPER_ADMIN':
            return RoleEnum.SuperAdmin;
        case 'ROLE_ADMIN':
            return RoleEnum.Admin;
        default: 
            return RoleEnum.Staff;
    }
};

export const convertToLocate = (locate: string): LocaleEnum => {
    switch (locate) {
        case 'en_US':
            return LocaleEnum.ENGLISH;
        default: 
            return LocaleEnum.FRENCH;
    }
};

export const convertResponseUsers = (data: UsersResponseRawType[])
: UsersResponseType[] => data.map((o) => ({
    id: o.id,
    key: o.id,
    username: o.entityname,
    locale: convertToLocate(o.locale),
    role: convertToRole(o.roles),
    email: o.email,
    createdDate: moment(o.created_at)
}));

export const convertResponseUserDetails = (data: UserDetailsResponseRawType)
: UserDetailsResponseType => ({
    id: data.id,
    username: data.entityname,
    role: convertToRole(data.roles),
    locale: convertToLocate(data.locale),
    email: data.email,
});
