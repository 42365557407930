import { AxiosProgressEvent } from "axios";

export enum ThemeEnum {
    Dark = 0,
    Light = 1,
}

export enum ThemeNameEnum {
    Dark = 'dark',
    Light = 'light',
}

export enum LocaleEnum {
    ENGLISH = 'en_US',
    FRENCH = 'fr_FR'
}

export type ThemeType = {
    type: string,
    background: string,
    card: string,
    border: string,
    dropdown: string,
    normal: string,
    sub: string,
    blur: string,
    selection: string,
    iconSelection: string,
    iconTime: string,
    thead: string,
    theadSortIcon: string,
    tborder: string,
    texpandrow: string,
    switch: string,
    tableBorder: string,
    filterBackground: string
}

export type ItemsType = {
    label: string;
    disabled?: boolean;
    value: string | number;
}

export enum StatusEnum {
    Verified = 2,
    Completed = 1,
    Draft = 0,
}

export type DataSendFileRequestType = {
    form: FormData,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
}

export type APIResponse<T> = T

export type APIErrResponse = {
    class: string,
    detail?: string,
    message?: string,
    status: string,
    title: string,
}
