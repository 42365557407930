import styled, { createGlobalStyle } from 'styled-components';
import { Select } from 'antd';
import { mainTheme } from 'constant';
import { ThemeType } from 'utils';

export const CustomSelect: typeof Select = styled(Select)`
    &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    &.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        line-height: 40px;
        font-size: ${mainTheme.fontNormal}px;
    }

    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        height: 40px;
    }

    &:not(.ant-select-customize-input).ant-select-disabled .ant-select-selector,
    &:not(.ant-select-customize-input) .ant-select-selector {
        background-color: ${(props) => props.theme.card};
        color: ${(props) => props.theme.sub};
        border-color: ${(props) => props.theme.border};
    }

    &:not(.ant-select-disabled):hover .ant-select-selector,
    &:not(.ant-select-customize-input).ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
        border-color: ${mainTheme.primary};
    }

    &.ant-select-multiple {
        .ant-select-selection-item {
            background: ${(props) => props.theme.selection};
            border-color: ${(props) => props.theme.border};
        }

        .ant-select-selection-item-remove {
            color: ${(props) => props.theme.iconSelection};
        }
    }

    .ant-select-dropdown {
        background-color: ${(props) => props.theme.card}; 
    }

    .ant-select-arrow {
        color: ${(props) => props.theme.iconTime};
    }

    .ant-select-selection-placeholder {
        color: ${(props) => props.theme.theadSortIcon};
    }
`;

export const GlobalSelectStyle = createGlobalStyle<{theme: ThemeType}>`
    .ant-select-dropdown {
        background-color: ${(props) => props.theme.dropdown};
    }

    .ant-select-item-empty {
        color: ${(props) => props.theme.iconTime};
    }

    .ant-select-item {
        color: ${(props) => props.theme.sub};
        &.ant-select-item-option-disabled {
            color: ${(props) => props.theme.iconTime};
        }
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        color: ${(props) => props.theme.sub};
        background-color: ${(props) => props.theme.selection};
    }
`;
