
import { useMutation } from '@tanstack/react-query';
import { HttpClient } from 'utils/httpClient';
import {
    LoginRequestType, ForgotPasswordRequestType, ResetPasswordRequestType,
    LoginResponseRawType, AuthRawType, AuthType
} from './types';
import { convertResponseLogin } from './adapter';
import { AxiosError } from 'axios';
import jwtDecode from "jwt-decode";
import { APIErrResponse } from 'utils';

export const getUserInfo = (token: string): AuthType => {
    return convertResponseLogin(jwtDecode<AuthRawType>(token));
};

export const useLogin = () => useMutation<
    LoginResponseRawType, AxiosError<APIErrResponse>, LoginRequestType
>((data: LoginRequestType) => HttpClient.post<LoginResponseRawType>('user-management/authenticate', data).then((res) => {
    return res;
}));

export const fetchLogout = async () => {
    HttpClient.get<unknown>('user-management/logout');
};

export const useForgotPassword = () => useMutation<
    boolean, AxiosError<APIErrResponse>, ForgotPasswordRequestType
>((data: ForgotPasswordRequestType) => HttpClient.post<boolean>('user-management/reset-password', data).then(() => {
    return true;
}));

export const useResetPassword = (token: string) => useMutation<
    boolean, AxiosError<APIErrResponse>, ResetPasswordRequestType
>((data: ResetPasswordRequestType) => HttpClient.post<boolean>('user-management/set-password', { ...data, token }).then(() => {
    return true;
}));
