import {
    UsersResponseType, UsersResponseRawType, UserCreatingRequestType, UserDetailsResponseType,
    UserDetailsResponseRawType,
} from './types';
import { convertResponseUserDetails, convertResponseUsers } from './adapter';
import { useQuery, useMutation } from '@tanstack/react-query';
import { HttpClient } from 'utils/httpClient';
import { AxiosError } from 'axios';
import { APIErrResponse } from 'utils';

export const useUsers = () => {
    return useQuery<UsersResponseType[], AxiosError<APIErrResponse>>({
        queryKey: ['users'],
        retry: false,
        refetchInterval: false,
        retryOnMount: true,
        refetchOnWindowFocus: false,
        queryFn: async (): Promise<UsersResponseType[]> => {
            const response = await HttpClient.get<UsersResponseRawType[]>('user-management/users');
    
            return convertResponseUsers(response);
        },
    });
}

export const useCreatingUser = () => useMutation<
    boolean, AxiosError<APIErrResponse>, UserCreatingRequestType
>((data: UserCreatingRequestType) => HttpClient.post<boolean>('user-management/users', data).then(() => {
    return true;
}));

export const useEditingUser = (id: string) => useMutation<
    boolean, AxiosError<APIErrResponse>, UserCreatingRequestType
>((data: UserCreatingRequestType) => HttpClient.put<boolean>(`user-management/${id}`, data).then(() => {
    return true;
}));

export const useDeletingUser = () => useMutation<
    boolean, AxiosError<APIErrResponse>, string
>((id: string) => HttpClient.delete<boolean>(`user-management/users/${id}`).then(() => {
    return true;
}));

export const useUserDetail = (id: string) => {
    return useQuery<UserDetailsResponseType, AxiosError<APIErrResponse>>({
        queryKey: [`user-detail-${id}`],
        retry: false,
        refetchInterval: false,
        retryOnMount: true,
        refetchOnWindowFocus: false,
        queryFn: async (): Promise<UserDetailsResponseType> => {
            const response = await HttpClient.get<UserDetailsResponseRawType>(
                `user-management/${id}`
            );
    
            return convertResponseUserDetails(response);
        },
    });
}
