export const PUBLIC_ROUTE = {
    SIGN_IN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    PAGE_404: '/404',
};

export const PRIVATE_ROUTE = {
    NOT_FOUND: '/not-found',
    USERS: '/users',
    CLIENTS: '/clients',
    PROFILE: '/profile',
    ACCOUNTING_ACCESS: '/accounting-access',
    CONVERSION_TABLE: '/conversion-table',
    INVOICES: '/invoices',
    INF: '/inf',
    IMA: '/ima',
    MPE: '/mpe'
};

export const ACTION_ROUTE = {
    create: 'create',
    edit: 'edit',
    detail: 'detail'
};
