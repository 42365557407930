import React, { useContext } from 'react';
import { Outlet , Navigate } from 'react-router-dom';
import { PUBLIC_ROUTE } from 'constant';
import { authContext } from 'utils';

export const PrivateRoute = () => {
    const { user } = useContext(authContext);

    if (user) {
        return <Outlet />;
    }

    return (
        <Navigate to={PUBLIC_ROUTE.SIGN_IN} />
    );
};
