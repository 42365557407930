import styled from 'styled-components';
import { mainTheme } from 'constant';
import { Layout } from 'antd';

export const CustomSider = styled(Layout.Sider)`
    background-color: #202F47;

    .ant-menu-inline, .ant-menu-inline-collapsed {
        background: transparent;
        color: #fff;

        > .ant-menu-item {
            height: 42px;
            line-height: 42px;
            margin: 0 0 8px;

            &:after {
                border-right-color: #B2CDF8;
            }
        }

        .ant-menu-item-selected {
            background-color: ${mainTheme.primary} !important;
            color: #fff;
            &:hover {
                color: #fff;
            }
        }
    }

    .ant-menu-inline {
        padding: 0 24px;
    }

    &.ant-layout-sider-collapsed .ant-menu-inline-collapsed > .ant-menu-item {
        margin: 0 15px 8px;
        padding: 0;

        .ant-menu-item-icon {
            position: relative;
            left: 12px;
        }

        .icon-outside {
            position: relative;
            left: 14px;
            margin-right: 30px;
        }

        &.ant-menu-item-selected:after {
            transform: scaleY(1);
            opacity: 1;
            transition: transform .15s cubic-bezier(.645,.045,.355,1),opacity .15s cubic-bezier(.645,.045,.355,1);
        }
    }

    .icon-outside {
        margin-right: 10px;
        svg {
            position: relative;
            top: 2px;
        }

        &.mpe-icon svg {
            top: 1px;
        }
    }

    .ant-menu-item {
        &:hover .icon-outside svg path {
            fill: ${mainTheme.primary};
        }

        &.ant-menu-item-selected:hover .icon-outside svg path {
            fill: #fff;
        }
    }
`;
