import styled, { createGlobalStyle } from 'styled-components';
import { mainTheme } from 'constant';
import { DatePicker } from 'antd';
import { ThemeType } from 'utils';

export const CustomDatePicker = styled(DatePicker)`
    background-color: ${(props) => props.theme.card};
    border-color: ${(props) => props.theme.border};
    width: 100%;
    .ant-picker-input > input {
        color: ${(props) => props.theme.sub};
        ::placeholder {
            color: ${(props) => props.theme.theadSortIcon};
        }
    }

    .ant-picker-suffix {
        color: ${(props) => props.theme.iconTime};
    }

    .ant-picker-clear {
        background-color: ${(props) => props.theme.card};
        color: ${(props) => props.theme.iconTime};
        &:hover {
            color: ${(props) => props.theme.iconSelection};
        }
    }

    &:hover, &.ant-picker-focused {
        border-color: ${mainTheme.primary};
    }

    &.ant-picker-disabled {
        background-color: ${(props) => props.theme.card};
        border-color: ${(props) => props.theme.border};
        .ant-picker-suffix {
            color: ${(props) => props.theme.iconTime};
        }
    }
`;

export const GlobalDatePickerStyle = createGlobalStyle<{theme: ThemeType}>`
    .ant-picker-panel-container {
        background: ${(props) => props.theme.dropdown};
    }

    .ant-picker-header {
        color: ${(props) => props.theme.normal};
        border-bottom: 1px solid ${(props) => props.theme.tborder};

        button {
            color: ${(props) => props.theme.iconTime};
            &:hover {
                color: ${(props) => props.theme.normal};
            }
        }
    }

    .ant-picker-panel {
        border: 1px solid ${(props) => props.theme.tborder};
    }

    .ant-picker-header-view button {
        color: inherit;
        &:hover {
            color: ${mainTheme.primary};
        }
    }

    .ant-picker-content {
        th {
            color: ${(props) => props.theme.normal}; 
        }
    }

    .ant-picker-cell {
        color: ${(props) => props.theme.iconTime};
    }

    .ant-picker-cell-in-view {
        color: ${(props) => props.theme.normal};
    }

    .ant-picker-range-arrow::after {
        border: 5px solid ${(props) => props.theme.tborder};
        border-color: ${(props) => props.theme.dropdown} ${(props) => props.theme.dropdown} transparent transparent;
    }

    .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner,
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
        background: ${(props) => props.theme.selection};
    }

    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        background: ${mainTheme.primary};
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border: 1px solid ${mainTheme.primary};
    }

    .ant-picker-panel .ant-picker-footer {
        border-top: 1px solid ${(props) => props.theme.tborder};
    }

    .ant-picker-time-panel-column:not(:first-child),
    .ant-picker-datetime-panel .ant-picker-time-panel {
        border-left: 1px solid ${(props) => props.theme.tborder};
    }

    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
        color: ${(props) => props.theme.sub};
    }
`;
