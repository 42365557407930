import styled from 'styled-components';
import { InputNumber } from 'antd';
import { mainTheme } from 'constant';

export const CustomInputNumber = styled(InputNumber)`
    background: ${(props) => props.theme.card};
    border-color: ${(props) => props.theme.border};
    color: ${(props) => props.theme.sub};
    box-shadow: none;

    &:focus, &:hover, &.ant-input-focused {
        border-color: ${mainTheme.primary};
    }

    .ant-input-number-handler-wrap {
        background: ${(props) => props.theme.card};
    }

    .ant-input-number-handler {
        border-left: 1px solid ${(props) => props.theme.border};
        color: ${(props) => props.theme.iconSelection};
    }

    .ant-input-number-handler-down {
        border-top: 1px solid ${(props) => props.theme.border};
    }

    .ant-input-number-handler-up-inner, .ant-input-number-handler-down-inner {
        color: ${(props) => props.theme.iconSelection};
    }

    .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
    .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
        color: ${mainTheme.primary};
    }
`;
