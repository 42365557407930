import styled from 'styled-components';
import { Card } from 'antd';

export const CustomCard = styled(Card)`
    background: ${(props) => props.theme.card};
    border-color: ${(props) => props.theme.card};

    .ant-card-body {
        padding: 15px;
        background: ${(props) => props.theme.card};
    }

    .ant-card-head {
        background: ${(props) => props.theme.card};
        border-bottom: 1.5px solid ${(props) => props.theme.border};
    }

    .ant-card-head-title {
        color: ${(props) => props.theme.normal};
    }
`;
