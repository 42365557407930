export const IMAIcon = () => (
    <span className="icon-outside ima-icon">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
            viewBox="0 0 14 14"
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M4.36.767a.567.567 0 00-.39.154L.61 4.095a.567.567 0 00-.177.412v8.16c0 .313.254.566.567.566h12a.567.567 0 00.567-.566v-8.16a.566.566 0 00-.178-.412L10.029.92a.567.567 0 00-.39-.154H4.36zM4.585 1.9h1.848v2.04H2.425l2.16-2.04zm2.982 0v2.04h4.008L9.415 1.9H7.567zm4.866 10.2V5.073H1.567V12.1h10.866zm-5.488-1.038a.126.126 0 01-.044-.036L5.143 8.777a.127.127 0 01.1-.204h1.16V6.179c0-.07.056-.127.125-.127h.941c.07 0 .126.057.126.127v2.395h1.163c.105 0 .163.123.099.205l-1.758 2.247a.126.126 0 01-.154.036z"
                clipRule="evenodd"
            ></path>
        </svg>
    </span>
);

export const MPEIcon = () => (
    <span className="icon-outside mpe-icon">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="12"
            fill="none"
            viewBox="0 0 14 12"
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M13.678 4.08h-1.677l-.905-2.715-.91.456L8.366 0 6.544 1.365l-.91-.91-1.82 1.82L2.008 4.08H.322L2.81 9.06a1.683 1.683 0 102.793 1.424H8.21a1.683 1.683 0 102.918-1.302l2.55-5.102zM10.32 8.691l1.835-3.669H1.845l1.817 3.633a1.683 1.683 0 011.761.887h2.968a1.683 1.683 0 011.93-.85zM3.93 11a.682.682 0 100-1.365.682.682 0 000 1.365zm5.956 0a.682.682 0 100-1.365.682.682 0 000 1.365zm-2.92-5.53a.08.08 0 00-.028.022l-1.122 1.42a.08.08 0 00.063.13h.74v1.512c0 .044.036.08.08.08H7.3a.08.08 0 00.08-.08V7.041h.742a.08.08 0 00.063-.13L7.063 5.493a.08.08 0 00-.098-.022z"
                clipRule="evenodd"
            ></path>
        </svg>
    </span>
);
