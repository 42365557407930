import styled from 'styled-components';
import { mainTheme } from 'constant';
import { Tabs } from 'antd';

export const CustomTabs = styled(Tabs)`
    color: ${(props) => props.theme.sub};

    &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
    &.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
        color: ${mainTheme.primary};
        background-color: ${(props) => props.theme.card};
    }
    &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
    &.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
        color: ${(props) => props.theme.sub};
        background-color: ${(props) => props.theme.card};
    }

    .ant-tabs-tab-remove {
        color: ${(props) => props.theme.iconSelection};
    }

    &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
    &.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        background: ${(props) => props.theme.thead};
        border: 1px solid ${(props) => props.theme.tborder};
    }

    &.ant-tabs-top > .ant-tabs-nav::before,
    &.ant-tabs-bottom > .ant-tabs-nav::before,
    &.ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
        border-bottom: 1px solid ${(props) => props.theme.tborder};
    }
`;
