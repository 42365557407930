import styled from 'styled-components';
import { mainTheme } from 'constant';
import { Input } from 'antd';

export const CustomTextArea = styled(Input.TextArea)`
    background: ${(props) => props.theme.card};
    border-color: ${(props) => props.theme.border};
    color: ${(props) => props.theme.sub};

    &:focus, &:hover, &.ant-input-focused {
        border-color: ${mainTheme.primary};
    }

    &[disabled] {
        background: ${(props) => props.theme.card};
        border-color: ${(props) => props.theme.border};
        color: ${(props) => props.theme.sub};
    }
`;
