import styled from 'styled-components';
import { mainTheme } from 'constant';
import { Layout } from 'antd';

const AntdContent = Layout.Content;
export const Content = styled(AntdContent)`
    &.full {
        max-width: 1135px;
        width: 100%;
        padding: 0 ${mainTheme.padding}px;
        margin: 40px auto;

        .form-default {
            width: 500px;
            max-width: 100%;
            margin: 0 auto;
        }

        .user-info {
            width: 392px;
            margin: 0 auto;
        }
    }

    &.normal {
        width: 100%;
        padding: 40px;
        overflow: auto;

        .client-info {
            width: 450px;
        }

        .client-profile-form {
            width: 500px;
            max-width: 100%;
        }
    }

    .form-item-group-btn {
        margin-top: 40px;
    }
`;
