import { lazy } from 'react';
import { PUBLIC_ROUTE, PRIVATE_ROUTE, ACTION_ROUTE } from 'constant';
import {
    UserOutlined, CalculatorOutlined, FileTextOutlined, TableOutlined, BarcodeOutlined
} from '@ant-design/icons';
import { IMAIcon, MPEIcon } from 'components/UI';

export const publicRoutes = [{
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('containers/SignIn/SignIn')),
}, {
    path: PUBLIC_ROUTE.FORGOT_PASSWORD,
    component: lazy(() => import('containers/ForgotPassword/ForgotPassword')),
}, {
    path: `${PUBLIC_ROUTE.RESET_PASSWORD}/:token`,
    component: lazy(() => import('containers/ResetPassword/ResetPassword')),
}];

export const privacyRoutes = [{
    path: PRIVATE_ROUTE.CLIENTS,
    component: lazy(() => import('containers/Clients/Clients')),
}, {
    path: `${PRIVATE_ROUTE.CLIENTS}/${ACTION_ROUTE.create}`,
    component: lazy(() => import('containers/Clients/CreatingClient')),
}, {
    path: `${PRIVATE_ROUTE.CLIENTS}/:clientId/*`,
    component: lazy(() => import('containers/ClientDetail/ClientDetail')),
}, {
    path: PRIVATE_ROUTE.USERS,
    component: lazy(() => import('containers/Users/Users')),
}, {
    path: `${PRIVATE_ROUTE.USERS}/${ACTION_ROUTE.create}`,
    component: lazy(() => import('containers/Users/CreatingUser')),
}, {
    path: `${PRIVATE_ROUTE.USERS}/${ACTION_ROUTE.edit}/:userId`,
    component: lazy(() => import('containers/Users/EditingUser')),
}, {
    path: PRIVATE_ROUTE.PROFILE,
    component: lazy(() => import('containers/Profile/Profile')),
}];

export const clientRoutes = [{
    label: 'profile',
    icon: UserOutlined,
    path: PRIVATE_ROUTE.PROFILE,
    component: lazy(() => import('containers/ClientProfile/ClientProfile')),
}, {
    label: 'accounting_access',
    icon: CalculatorOutlined,
    path: PRIVATE_ROUTE.ACCOUNTING_ACCESS,
    component: lazy(() => import('containers/AccountingAccess/AccountingAccess')),
}, {
    label: 'conversion_table',
    icon: TableOutlined,
    path: PRIVATE_ROUTE.CONVERSION_TABLE,
    component: lazy(() => import('containers/ConversionTable/ConversionTable')),
}, {
    label: 'invoices',
    icon: FileTextOutlined,
    path: PRIVATE_ROUTE.INVOICES,
    component: lazy(() => import('containers/Invoices/Invoices')),
}, {
    label: 'inf',
    icon: BarcodeOutlined,
    path: PRIVATE_ROUTE.INF,
    component: lazy(() => import('containers/INF/INF')),
}, {
    label: 'ima',
    icon: IMAIcon,
    path: PRIVATE_ROUTE.IMA,
    component: lazy(() => import('containers/IMA/IMA')),
}, {
    label: 'eu_purchase',
    icon: MPEIcon,
    path: PRIVATE_ROUTE.MPE,
    component: lazy(() => import('containers/MPE/MPE')),
}];
