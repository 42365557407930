import i18next from 'i18next';
import moment, { Moment } from 'moment';
import { RoleEnum } from 'services/Users';
import { LocaleEnum } from './types';

export const renderDate = (date: number | string | Moment): string => {
    const newDate = moment(date);

    if (newDate.isValid()) {
        return newDate.format('DD/MM/YYYY HH:mm');
    }

    return '';
};

export const renderFormattedNumber = (num?: number): string => {
    if (num === 0) {
        return '0';
    }

    if (!num) {
        return '';
    }

    return num.toLocaleString(undefined, { maximumFractionDigits: 20 });
};

export const getRoleName = (role?: RoleEnum): string => {
    switch (role) {
        case RoleEnum.SuperAdmin: 
            return i18next.t('super_admin');
        case RoleEnum.Admin:
            return i18next.t('admin');
        default:
            return i18next.t('staff');
    }
};

export const getRoleColor = (role?: RoleEnum): string => {
    switch (role) {
        case RoleEnum.SuperAdmin: 
            return 'red';
        case RoleEnum.Admin:
            return 'magenta';
        default:
            return 'blue';
    }
};

export const getLocaleName = (locate?: LocaleEnum): string => {
    switch (locate) {
        case LocaleEnum.ENGLISH: 
            return i18next.t('english');
        default:
            return i18next.t('french');
    }
};

export const filterText = (value: string, text?: string) => {
    if (!text) {
        return true;
    }

    return value.toLowerCase().includes(text.toLowerCase());
};

export const filterDate = (value: Moment, range?: [Moment, Moment]) => {
    if (!range) {
        return true
    }

    return value.isBetween(range[0], range[1], undefined, '[]');
};

export const filterNumber = (value: number, num?: number) => {
    if (!num) {
        return true;
    }

    return value === Number(num);
};

export const filterOption = <T>(value: T, option?: T) => {
    if (!option) {
        return true;
    }

    return value === option;
};
