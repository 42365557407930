import styled from 'styled-components';
import { mainTheme } from 'constant';
import { Checkbox } from 'antd';

export const CustomCheckbox = styled(Checkbox)`
    color: ${(props) => props.theme.sub};

    .ant-checkbox {
        color: ${(props) => props.theme.sub};
    }

    .ant-checkbox-inner {
        background-color: ${(props) => props.theme.card};
        border: 1px solid ${(props) => props.theme.border};
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${mainTheme.primary};
    }
    
    &:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-checked:after,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border: 1px solid ${mainTheme.primary};
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner:after {
        background-color: ${mainTheme.primary};
    }
`;
