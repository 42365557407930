import styled from 'styled-components';
import { mainTheme } from 'constant';
import { Menu } from 'antd';

export const CustomMenu = styled(Menu)`
    background: ${(props) => props.theme.card};
    color: ${(props) => props.theme.sub};

    .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow,
    .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
        color: ${(props) => props.theme.sub};
    }

    .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
        background: ${(props) => props.theme.selection};
    }

    .ant-dropdown-menu-item-divider, .ant-dropdown-menu-submenu-title-divider {
        background-color: ${(props) => props.theme.tborder};
    }

    .ant-menu-sub.ant-menu-inline {
        background-color: ${(props) => props.theme.thead};
        color: ${(props) => props.theme.sub};
    }

    &:not(.ant-menu-horizontal) .ant-menu-item-selected,
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: ${(props) => props.theme.selection};
    }

    .ant-menu-item:hover,
    .ant-menu-submenu-selected,
    .ant-menu-item-selected {
        color: ${mainTheme.primary};
    }

    .ant-menu-item:after {
        border-right: 3px solid ${mainTheme.primary};
    }

    &.ant-menu-inline, &.ant-menu-vertical, &.ant-menu-vertical-left {
        border-right: 0;
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
        background-color: ${(props) => props.theme.selection};
    }
`;
