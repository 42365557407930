import styled from 'styled-components';
import { Form } from 'antd';

export const CustomForm: typeof Form = styled(Form)`
    .ant-form-item-label > label {
        color: ${(props) => props.theme.sub};
    }

    .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
        background: ${(props) => props.theme.card};
    }
`;
