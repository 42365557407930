import axios, {
    AxiosRequestConfig, AxiosInstance, AxiosResponse,
    AxiosError,
    AxiosProgressEvent,
} from 'axios';
import { url, LOCALSTORAGE_TOKEN_KEY, PUBLIC_ROUTE } from 'constant';
import { getDataOnDevice, APIResponse, deleteDataOnDevice, history } from 'utils';

export class HttpClient {
    private static _instance: AxiosInstance;

    static get instance(): AxiosInstance {
        if (!HttpClient._instance) {
            HttpClient._instance = HttpClient.create();
        }
        
        return HttpClient._instance;
    }

    static get getToken(): string {
        return getDataOnDevice(LOCALSTORAGE_TOKEN_KEY) || '';
    }

    private static create(): AxiosInstance {
        const requestConfig: AxiosRequestConfig = {
            withCredentials: true,
        };
        const instance = axios.create(requestConfig);

        return instance;
    }

    static post<T>(
        endpoint: string,
        data?: unknown,
    ): Promise<T> {
        return HttpClient.instance
            .post(
                `${url}${endpoint}`,
                data,
                {
                    headers: HttpClient.getHeader(endpoint),
                }
            )
            .then((res: AxiosResponse): Promise<T> => HttpClient.handleAxiosResponse<T>(res))
            .catch((err: AxiosError): Promise<T> => HttpClient.handleAxiosError<T>(err));
    }

    static put<T>(
        endpoint: string,
        data?: unknown,
    ): Promise<T> {
        return HttpClient.instance
            .put(
                `${url}${endpoint}`,
                data,
                {
                    headers: HttpClient.getHeader(),
                }
            )
            .then((res: AxiosResponse): Promise<T> => HttpClient.handleAxiosResponse<T>(res))
            .catch((err: AxiosError): Promise<T> => HttpClient.handleAxiosError<T>(err));
    }

    static get<T>(
        endpoint: string,
        params?: unknown,
    ): Promise<T> {
        return HttpClient.instance
            .get(
                `${url}${endpoint}`,
                {
                    params,
                    headers: HttpClient.getHeader(),
                },
            )
            .then((res: AxiosResponse): Promise<T> => HttpClient.handleAxiosResponse<T>(res))
            .catch((err: AxiosError): Promise<T> => HttpClient.handleAxiosError<T>(err));
    }

    static export<T>(
        endpoint: string,
        params?: unknown,
    ): Promise<T> {
        return HttpClient.instance
            .get(
                `${url}${endpoint}`,
                {
                    params,
                    responseType: 'arraybuffer',
                    headers: HttpClient.getHeader(),
                },
            )
            .then((res: AxiosResponse): Promise<T> => HttpClient.handleAxiosResponse<T>(res))
            .catch((err: AxiosError): Promise<T> => HttpClient.handleAxiosError<T>(err));
    }

    static delete<T>(
        endpoint: string,
        data?: unknown,
    ): Promise<T> {
        return HttpClient.instance
            .delete(
                `${url}${endpoint}`,
                {
                    data,
                    headers: HttpClient.getHeader(),
                },
            )
            .then((res: AxiosResponse): Promise<T> => HttpClient.handleAxiosResponse<T>(res))
            .catch((err: AxiosError): Promise<T> => HttpClient.handleAxiosError<T>(err));
    }

    static sendFile<T>(
        endpoint: string,
        data?: unknown,
        onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
    ): Promise<T> {
        return HttpClient.instance
            .post(
                `${url}${endpoint}`,
                data,
                {
                    headers: {
                        ...HttpClient.getHeader(),
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: onUploadProgress
                }
            )
            .then((res: AxiosResponse): Promise<T> => HttpClient.handleAxiosResponse<T>(res))
            .catch((err: AxiosError): Promise<T> => HttpClient.handleAxiosError<T>(err));
    }

    private static handleAxiosResponse<T>(res: AxiosResponse<APIResponse<T>>): Promise<T> {
        const { data } = res;

        return Promise.resolve<T>(data);
    }

    private static handleAxiosError<T>(err: AxiosError): Promise<T> {
        if (err?.response?.status === 401 && Object.values(PUBLIC_ROUTE).indexOf(history.location.pathname) === -1) {
            deleteDataOnDevice(LOCALSTORAGE_TOKEN_KEY);
            history.push(PUBLIC_ROUTE.SIGN_IN);
        }

        return Promise.reject<T>(err);
    }

    private static getHeader (endpoint?: string) {
        const token = HttpClient.getToken;

        if (token && endpoint !== 'user-management/reset-password') {
            return {
                Authorization: `Bearer ${token}`
            }
        }

        return {};
    }
}
