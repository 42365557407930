import styled from 'styled-components';
import { Table, TableProps } from 'antd';
import { mainTheme } from 'constant';
import { FunctionComponent } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomTable: FunctionComponent<TableProps<any>> = styled(Table)`
    border: 1px solid ${(props) => props.theme.tableBorder};

    .ant-table {
        color: ${(props) => props.theme.sub};
        background-color: ${(props) => props.theme.card};
    }

    .ant-table-sticky-holder {
        background-color: ${(props) => props.theme.card};
    }

    .ant-table-small .ant-table-thead > tr > th {
        background-color: ${(props) => props.theme.thead};
        border-bottom: 1px solid ${(props) => props.theme.tborder};
        color: ${(props) => props.theme.sub};
    }

    .ant-table-tbody > tr > td {
        border-bottom: 1px solid ${(props) => props.theme.tborder};
    }

    .ant-table-cell-fix-left, .ant-table-cell-fix-right {
        background-color: ${(props) => props.theme.card};
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
        background-color: ${(props) => props.theme.thead};
    }

    .ant-pagination-item a {
        color: ${(props) => props.theme.sub};
        &:hover {
            color: ${mainTheme.primary};
        }
    }

    .ant-pagination-item-active {
        background-color: ${(props) => props.theme.card};
        border-color: ${mainTheme.primary};
        a {
            color: ${mainTheme.primary};
        }
    }

    .ant-pagination-options-size-changer {
        &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background-color: ${(props) => props.theme.card};
            color: ${(props) => props.theme.sub};
            border-color: ${(props) => props.theme.border};
        }

        &.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
        &.ant-select:not(.ant-select-customize-input).ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
            border-color: ${mainTheme.primary};
        }

        .ant-select-dropdown {
            background-color: ${(props) => props.theme.card}; 
        }

        .ant-select-arrow {
            color: ${(props) => props.theme.iconTime};
        }
    }

    .ant-table-row-expand-icon {
        background-color: ${(props) => props.theme.card};
        border: 1px solid ${(props) => props.theme.border};
        color: 1px solid ${(props) => props.theme.sub};
        &:focus, &:hover, &:active {
            color: ${mainTheme.primary};
            border: 1px solid ${mainTheme.primary};
        }
    }

    tr.ant-table-expanded-row > td,
    tr.ant-table-expanded-row:hover > td {
        background-color: ${(props) => props.theme.texpandrow};
    }

    .ant-empty-normal {
        color: ${(props) => props.theme.iconTime};
    }

    .ant-table-tbody > tr.ant-table-placeholder:hover > td {
        background-color: ${(props) => props.theme.background};
    }

    td.ant-table-column-sort {
        background-color: ${(props) => props.theme.thead};
    }

    .ant-pagination-prev button,
    .ant-pagination-next button {
        color: ${(props) => props.theme.sub};
    }

    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
    .ant-pagination-disabled .ant-pagination-item-link,
    .ant-pagination-disabled:hover .ant-pagination-item-link,
    .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
        color: ${(props) => props.theme.iconTime};
    }

    .ant-table-sticky-scroll {
        background: ${(props) => props.theme.card};
        border-top: 1px solid ${(props) => props.theme.tborder};
    }

    .ant-table-tbody > tr.ant-table-row-selected > td {
        background: ${(props) => props.theme.iconTime};
    }

    .ant-checkbox {
        color: ${(props) => props.theme.sub};
    }

    .ant-checkbox-inner {
        background-color: ${(props) => props.theme.card};
        border: 1px solid ${(props) => props.theme.border};
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${mainTheme.primary};
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border: 1px solid ${mainTheme.primary};
    }

    .ant-table-body {
        overflow: auto auto !important;
    }

    .ant-table.ant-table-small .ant-table-footer,
    .ant-table.ant-table-small .ant-table-tbody>tr>td,
    .ant-table.ant-table-small .ant-table-thead>tr>th,
    .ant-table.ant-table-small .ant-table-title,
    .ant-table.ant-table-small tfoot>tr>td,
    .ant-table.ant-table-small tfoot>tr>th {
        padding: 16px;
    }

    .ant-table.ant-table-small tr > th {
        font-weight: bold;
    }

    .ant-table.ant-table-small tr > td {
        cursor: pointer;
    }
`;
